import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, AuthAdapter } from './auth.adapter';

const { selectEntities } = AuthAdapter.getSelectors();

const getAuthState = createFeatureSelector<AuthState>('auth');

export const getLoggedUser = createSelector(getAuthState, (state: AuthState) => {
    const entities = selectEntities(state);
    return entities[0] ? entities[0] : null;
});
