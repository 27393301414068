import { createReducer, Action, on } from '@ngrx/store';
import * as RecentlyActions from './recently.actions';
import { Recently } from '../core/model/recently';

export const recentliesFeatureKey = 'recentlies';

export interface RecentlyState {
    recentlies: Recently[];
}

export const initialState: RecentlyState = {
    recentlies: []
};

const recentlyReducer = createReducer(
    initialState,
    on(RecentlyActions.saveRecentlies, (state, 
        { recentlies }) => {
            return {
                recentlies
            };
        }
    ),
    on(RecentlyActions.saveRecently, (state, 
        { recently }) => {
            let recentlyState = [...state.recentlies];

            let existingRecently = recentlyState.find((recentliesItem) => recentliesItem.serialNumber === recently.serialNumber)

            if( !existingRecently ) {
                recentlyState.push(recently);
            }

            return {
                recentlies: recentlyState
            };
        }
    ),
    on(RecentlyActions.removeRecentlyList, (state, 
        {  }) => {
            const recentlies = [...state.recentlies];
            return {
                recentlies: recentlies
            };
        }
    )
);

export function reducer( state: RecentlyState, action: Action) {
    return recentlyReducer(state, action);
}
