import { createAction, props } from '@ngrx/store';
import { ResultType } from '../core/model/result';


export enum DocumentActionTypes {
    requestDocument = '[API-DOCUMENT] REQUEST DOCUMENT',
    requestDocuments = '[API-DOCUMENT] REQUEST DOCUMENTS',
    requestNextDocuments = '[API-DOCUMENT] REQUEST NEXT DOCUMENTS',
    updateDocumentsResult = '[API-DOCUMENT] UPDATE DOCUMENTS RESULT',
    updateDocumentsKey = '[API-DOCUMENT] UPDATE DOCUMENTS KEY',
    setDocumentResult = '[API-DOCUMENT] SET DOCUMENT RESULT',
    setDocumentMessageResult = '[API-DOCUMENT] SET DOCUMENT MESSAGE RESULT',
    cleanStateDocument = '[API-DOCUMENT] CLEAN DOCUMENT STATE',
    requestDocumentStatus = '[API-DOCUMENT] REQUEST DOCUMENT STATUS',
    requestDocumentSucess = '[API-DOCUMENT] SUCESS DOCUMENT BY MACHINE VERSION',
}

export const requestDocument = createAction(DocumentActionTypes.requestDocument, props<{ version: any }>());
export const requestDocuments = createAction(DocumentActionTypes.requestDocuments, props<{ id: string }>());
export const requestNextDocuments = createAction(
    DocumentActionTypes.requestNextDocuments,
    props<{ key: string; lastItem: string }>()
);
export const updateDocumentsResult = createAction(
    DocumentActionTypes.updateDocumentsResult,
    props<{ result: any; resultType?: ResultType }>()
);
export const updateDocumentsKey = createAction(DocumentActionTypes.updateDocumentsKey, props<{ key: string }>());
export const setDocumentResult = createAction(
    DocumentActionTypes.setDocumentResult,
    props<{ result: any; resultType?: any }>()
);
export const setResultTypeDocument = createAction(
    DocumentActionTypes.setDocumentMessageResult,
    props<{ resultType: ResultType, statusId: string }>()
);

export const cleanStateDocument = createAction(DocumentActionTypes.cleanStateDocument);

export const requestDocumentStatus = createAction(DocumentActionTypes.requestDocumentStatus, props<{ id: string }>());
export const requestDocumentSucess = createAction(DocumentActionTypes.requestDocumentSucess,
    props<{
        result: any
    }>()
);
