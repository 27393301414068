import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import * as recentlyReducer from './state/recently.reducer';
import { StoreModule } from '@ngrx/store';
import { RecentlyEffects } from './state/recently.effects';
import { EffectsModule } from '@ngrx/effects';
import { ConfigurationService } from '@modules/documents/core/service/configuration.service';

@NgModule({
    imports: [
        HttpClientModule,
        CommonModule,
        StoreModule.forFeature(recentlyReducer.recentliesFeatureKey, recentlyReducer.reducer),
        EffectsModule.forFeature([RecentlyEffects]),
    ],
    providers: [
        RecentlyEffects,        
        ConfigurationService          
    ]
})

export class RecentlyModule {}
