import { createReducer, Action, on } from '@ngrx/store';
import * as FavoritesActions from './favorites.actions';
import { Favorite } from '../core/model/favorite';

export const favoritesFeatureKey = 'favorites';

export interface FavoritesState {
    favorites: Favorite[];
}

export const initialState: FavoritesState = {
    favorites: []
};

const favoritesReducer = createReducer(
    initialState,
    on(FavoritesActions.saveFavorites, (state, 
        { favorites }) => {
            return {
                favorites
            };
        }
    ),
    on(FavoritesActions.saveFavorite, (state, 
        { favorite }) => {
            let favoriteState = [...state.favorites];

            let existingFavorite = favoriteState.find((favoritesItem) => favoritesItem.serialNumber === favorite.serialNumber)

            if( !existingFavorite ) {
                favoriteState.push(favorite);
            }

            return {
                favorites: favoriteState
            };
        }
    ),
    on(FavoritesActions.removeFavorite, (state, 
        { favorite }) => {
            const favorites = [...state.favorites];
            const filteredFavorites = favorites.filter( (item) => item.serialNumber !== favorite.serialNumber )
            return {
                favorites: filteredFavorites
            };
        }
    )
);

export function reducer( state: FavoritesState, action: Action) {
    return favoritesReducer(state, action);
}
