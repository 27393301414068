import { createReducer, Action, on } from '@ngrx/store';
import * as SettingsActions from './setting.actions';
import { SettingAdapter, SettingState } from './setting.adapter';
// import { Language } from '../core/model/setting';

export const initialState: SettingState = SettingAdapter.getInitialState();

const dataApiReducer = createReducer(
    initialState,
    on(SettingsActions.setLanguage, (state, { language }) => {
        return SettingAdapter.setOne({ id: 0, language: { currentLanguage: language } }, state);
    })
    /*   on(SettingsActions.getLanguage, (state) => {
           return state;
       })
       */
);

export function reducer(state: SettingState, action: Action) {
    return dataApiReducer(state, action);
}
