import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ProductApiService } from '../infrastructure/service/machine.service';
import {
    ProductActionTypes,
    setMachineResult,
    setVersionHistory,
    updateMachineKey,
    updateMachineResult,
    setMachineHistory
} from './product.actions';
import { of } from 'rxjs';
import { ApiError } from '../core/model/api-error';
import { dismissLoading } from '@modules/response/actions';

@Injectable()
export class ProductEffects {
    getMachine$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProductActionTypes.requestMachine),
            mergeMap((action: any) =>
                this.productApiService.getMachine(action.serialNumber, action.productNumber, action.version).pipe(
                    map((result: any) => setMachineResult({ result })),
                    catchError((error: ApiError) =>
                        of(setMachineResult({ result: error.message, resultType: error.type }))
                    )
                )
            )
        );
    });

    getVersionHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProductActionTypes.requestVersionHistory),
            mergeMap((action: any) =>
                this.productApiService.getVersionHistory(action.serialNumber, action.productNumber).pipe(
                    map((result: any) => setVersionHistory({ versionhistory: result })),
                    catchError((error: ApiError) =>
                        of(setMachineResult({ result: error.message, resultType: error.type }))
                    )
                )
            )
        );
    });

    getMachines$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProductActionTypes.requestMachines),
            mergeMap((action: any) =>
                this.productApiService.getMachines(action.serialNumber, action.productNumber).pipe(
                    mergeMap((result: any) => [
                        setMachineResult({ result }),
                        updateMachineKey({ serialNumber: action.serialNumber, productNumber: action.productNumber }),
                    ]),
                    catchError((error: ApiError) =>
                        of(setMachineResult({ result: error.message, resultType: error.type }))
                    )
                )
            )
        );
    });

    getNextMachines$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProductActionTypes.requestNextMachines),
            mergeMap((action: any) =>
                this.productApiService.getNextMachines(action.serialNumber, action.productNumber, action.lastItem).pipe(
                    map((result: any) => updateMachineResult({ result })),
                    catchError((error: ApiError) =>
                        of(updateMachineResult({ result: error.message, resultType: error.type }))
                    )
                )
            )
        );
    });

    navigateToMachineDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProductActionTypes.navigateToMachineDetails),
            map((action: any) => {
                this.productApiService.openMachine(
                    action.serialnumber,
                    action.manufacturerproductnumber,
                );
                return dismissLoading({});
            })
        )
    });

    getMachineHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProductActionTypes.requestMachineHistory),
            mergeMap((action: any) =>
                this.productApiService.getMachineHistory(action.serialNumber, action.productNumber).pipe(
                    map((result: any) => setMachineHistory({ machinehistory: result })),
                    catchError((error: ApiError) =>
                    //if no machine history exists, just return an empty history
                        of(setMachineHistory({ machinehistory: undefined }))
                    )
                )
            )
        );
    });

    constructor(private actions$: Actions, private productApiService: ProductApiService) {}
}
