import { createAction, props } from '@ngrx/store';
import { LoggedUser } from '../core/model/loggedUser';
import { ErrorAuth } from '../core/model/error';

export enum AuthActionTypes {
    login = '[AUTH] LOGIN WITH REDIRECTION',
    loginSilently = '[AUTH] LOGIN SILENTLY',
    loginRedirectionSucess = '[AUTH] LOGIN REDIRECTION SUCESS',
    logout = '[AUTH] LOGOUT',
    logoutSucess = '[AUTH] LOGOUT SUCESS',
    setLoggedUser = '[AUTH] SET LOGGED USER',
    requestLoggedUserRoles = '[AUTH] REQUEST LOGGED USER ROLES',
    setErrorCode = '[AUTH] ERROR',
    removeErrorCode = '[AUTH] CLEAN ERROR',
    cleanState = '[AUTH] CLEAN STATE',
}

export const login = createAction(AuthActionTypes.login);
export const loginRedirectionSucess = createAction(AuthActionTypes.loginRedirectionSucess);
export const loginSilently = createAction(AuthActionTypes.loginSilently);

export const logout = createAction(AuthActionTypes.logout);
export const logoutSucess = createAction(AuthActionTypes.logoutSucess);

export const setLoggedUser = createAction(AuthActionTypes.setLoggedUser, props<{ loggedUser: LoggedUser }>());
export const setErrorCode = createAction(AuthActionTypes.setErrorCode, props<{ error: ErrorAuth }>());

export const removeErrorCode = createAction(AuthActionTypes.removeErrorCode);
export const cleanState = createAction(AuthActionTypes.cleanState);
