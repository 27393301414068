import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import * as authReducer from './state/auth.reducer';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './state/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { ApiService } from '@core/services/api/api.service';
import { AuthConfig } from './core/model/authConfig';
import { AuthConfiguration } from './auth-configuration.token';
import { ConfigurationService } from './infrastructure/service/congifuration.service';

@NgModule({
    imports: [
        // Angular
        HttpClientModule,
        CommonModule,
        // Store
        StoreModule.forFeature('auth', authReducer.reducer),
        EffectsModule.forFeature([AuthEffects]),
    ],
    providers: [ApiService, AuthEffects],
})
export class AuthModule {
    static forRoot(authConfig: AuthConfig): ModuleWithProviders<any> {
        return {
            ngModule: AuthModule,
            providers: [
                ConfigurationService,
                {
                    provide: AuthConfiguration,
                    useValue: authConfig,
                },
            ],
        };
    }
}
