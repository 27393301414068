import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api/api.service';
import { AttributeNodeModel } from '@core/models/attribute-node.model';
import { APPLICATION_CONSTANTS } from '@core/constants/application-data.constants';
import { DOCUMENT_CONSTANTS } from '@core/constants/documentconstant';
import { DocumentInfoModel } from '@modules/documents/core/model/document-info';
import { SignedCookie, SignedUrl } from '@core/models/api-document.model';
@Injectable({
    providedIn: 'root',
})

export class DocumentApiService extends ApiService {
    uriIssuerDocument = 'document/issuer';
    uriIssuerDocumentRoot = 'machine';
    uriSignedCookieDocument = 'signedcookie';
    uri = 'machine/';
    url = super.urlApi;
    headers = {};

    getDocument(id: string) {
        return this.get(`${this.uri}/${encodeURIComponent(`${id}`)}`);
    }
    getDocuments(id: string) {
        return this.get(`${this.uri}/search?documentid=${encodeURIComponent(`${id}`)}&includetotal=true`);
    }
    getNextDocuments(id: string, lastItem: string) {
        return this.get(
            `${this.uri}/search?documentid=${encodeURIComponent(`${id}`)}&includetotal=true&lastitem=${lastItem}`
        );
    }

    public extractDocumentIdsFromMachineVersion(version: any): string[] {
        let documentIDArray: string[] = [];
        const machineInfo = version.currentversion || version;
        let machineDocuments = machineInfo.machine.CAEXFile.InstanceHierarchy.InternalElement.ExternalInterface;
        if(!Array.isArray(machineDocuments)){
          machineDocuments = [machineDocuments];
        }
          machineDocuments.forEach(document => {
            // Filter out anything that is not a manual or certificate
            if (document[DOCUMENT_CONSTANTS.NAME_FIELD_ATRIBUTE].RefBaseClassPath ===
                DOCUMENT_CONSTANTS.IS_MANUAL_OR_CERTIFICATE) { // It's a manual or certificate.
              if (Array.isArray(document.Attribute)) {
                for (const attribute of document.Attribute) {
                  const documentID: string = this.findAttributeValue(DOCUMENT_CONSTANTS.DOCUMENT_ID, attribute);
                  if (documentID !== '') { documentIDArray.push(documentID); }
                }
              } else if (document.Attribute !== null && typeof document.Attribute === 'object') {
                const documentID: string = this.findAttributeValue(DOCUMENT_CONSTANTS.DOCUMENT_ID, document.Attribute);
                if (documentID !== '') { documentIDArray.push(documentID); }
              }
            }
          });
          documentIDArray = documentIDArray.filter((value, index, self) => self.indexOf(value) === index);
        return documentIDArray;
      }

      public findAttributeValue(attributeName: string, node: AttributeNodeModel) {
        const attributeNode = this.findAttributeNode(attributeName, node);
        if (attributeNode) {
          const value = attributeNode.Value;
          if (value !== undefined) {
            return value;
          } else {
            return '';
          }
        }
        return '';
      }

      public findAttributeNode(attributeName: string, node: AttributeNodeModel): AttributeNodeModel {
        try {
          if (attributeName.localeCompare(node[APPLICATION_CONSTANTS.NAME_FIELD_ATRIBUTE].Name) === 0) {
            return node;
          }
          if (node.Attribute !== undefined) {
            for (const childAttribute of node.Attribute) {
              const result = this.findAttributeNode(attributeName, childAttribute);
              if (result !== undefined) {
                return result;
              }
            }
          }
        } catch (error) {
          return undefined;
        }
      }

      public async getDocumentInfoById(documentId: string): Promise<any> {
        const endpoint = "document/";
        if (!documentId) {
          throw new Error("DocumentId or|and serialNumber is not defined");
        } else {
          const url = `${encodeURIComponent(documentId)}`;
          try {
            return (await this.get(endpoint + url).toPromise()) as Promise<
              DocumentInfoModel
            >;
          } catch (error) {
            return {};
          }
        }
      }

      public async openDocument(location: string, serialNumber: string, productNumber: string, documenID: string, version: number) {
        const extensionRegExp = /\.[0-9a-z]+$/i;
        const result = location.match(extensionRegExp);
        if (result) {
          const extension = result[0];
          if (DOCUMENT_CONSTANTS.EXTENSIONS_USING_COOKIES.every(cookieExtension => extension.includes(cookieExtension))) {
            const response: SignedCookie = await this.getCookiesDocument(serialNumber, productNumber, documenID);

            this.openDocumentCookies(serialNumber, productNumber, documenID, response.cookie);
          } else {
            const response: SignedUrl = await this.getSignedUrlDocument(serialNumber, productNumber, documenID, version);
            window.open(response.url, '_blank');
          }
        }

      }

      private openDocumentCookies(serialNumber: string, productNumber: string, documenId: string, signedCookies: string) {
        const url = this.getUrlOpenDocument(serialNumber, productNumber, documenId, signedCookies);
        window.open(url, '_blank');
      }

      public getSignedUrlDocument(machineSerialNumber: string, machineProductNumber: string, documentId: string, version: number): Promise<any> {
        const key = this.getKey(machineSerialNumber, machineProductNumber);
        const uri = `${this.uriIssuerDocumentRoot}/${key}/${this.uriIssuerDocument}/${documentId}/signedurl?version=${version}`;
        return this.get(uri).toPromise();
      }


      public getCookiesDocument(machineSerialNumber: string, machineProductNumber: string, documentId: string): Promise<any> {
        const key = this.getKey(machineSerialNumber, machineProductNumber);
        const uri = `${this.uriIssuerDocumentRoot}/${key}/${this.uriIssuerDocument}/${documentId}/${this.uriSignedCookieDocument}`;
        return this.get(uri).toPromise();
      }


      public getUrlOpenDocument(machineSerialNumber: string, machineProductNumber: string, documentId: string, signedCookies: string) {
        const key = this.getKey(machineSerialNumber, machineProductNumber);
        let url = `${this.url}${this.uri}{encodeURIComponent(key)}/${this.uriIssuerDocument}/${documentId}/${this.uriSignedCookieDocument}/open`;
        url += `?signedcookie=${signedCookies}`;
        return url;
      }


      private getKey(machineSerialNumber: string, machineProductNumber: string) {
        return `${machineSerialNumber}${APPLICATION_CONSTANTS.SEPARATION_CHARACTER_SERIALNUMBER_PRODUCTNUMBER}${machineProductNumber}`;
      }
}
