import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { API_CONSTANTS } from '../constants/api.constant';
import { Store } from '@ngrx/store';
import { AppState, AuthSelectors, LoggedUser, AuthActions } from 'src/app/state';
import { flatMap, first, catchError, skipWhile } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.store.select(AuthSelectors.getLoggedUser).pipe(
            first(),
            flatMap((loggedUser: LoggedUser) => {
                const loggedUserToken = loggedUser ? loggedUser.token : '';

                const request = req.clone({
                    setHeaders: {
                        [API_CONSTANTS.HEADER_TOKEN]: loggedUserToken,
                    },
                });

                return next.handle(request).pipe(
                    catchError((error) => {
                        if (error && (error.status === 401 || error.status === 403)) {
                            this.store.dispatch(AuthActions.loginSilently());

                            return this.store.select(AuthSelectors.getLoggedUser).pipe(
                                skipWhile((checkUser: LoggedUser) => {
                                    const loggedUserExpiration = checkUser.expiration;
                                    const expirationDate = new Date();
                                    expirationDate.setTime(loggedUserExpiration);

                                    return expirationDate < new Date();
                                }),
                                flatMap((refreshedTokenUser: LoggedUser) => {
                                    const expirationDate = new Date();
                                    expirationDate.setTime(refreshedTokenUser.expiration);

                                    const refreshedTokenRequest = req.clone({
                                        setHeaders: {
                                            [API_CONSTANTS.HEADER_TOKEN]: refreshedTokenUser.token,
                                        },
                                    });

                                    return next.handle(refreshedTokenRequest);
                                })
                            );
                        } else {
                            return throwError(error);
                        }
                    })
                );
            })
        );
    }
}
