import { createReducer, Action, on } from '@ngrx/store';
import * as ResponseActions from './response.actions';
import { ResponseState } from './response';
import { ProductActions} from '@modules/product';


export const initialState: ResponseState = { loading: false };

const responseReducer = createReducer(
    initialState,
    on(ResponseActions.setLoading, (state: ResponseState, { loading }) => {
        return { loading };
    }),
    on(ResponseActions.dismissLoading, (state: ResponseState, {}) => {
        return { loading: false };
    }),
    on(ProductActions.navigateToMachineDetails, (state: ResponseState, {}) => {
        return { loading: false };
    }),
);

export function reducer(state: ResponseState, action: Action) {
    return responseReducer(state, action);
}
