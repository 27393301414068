import { Injectable, Inject } from '@angular/core';
import { AuthConfiguration } from '@modules/auth/auth-configuration.token';
import { AuthConfig } from '@modules/auth';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    private authConfig: AuthConfig;

    constructor(@Inject(AuthConfiguration) authConfig) {
        this.authConfig = authConfig;
    }

    getAuthConfiguration(): AuthConfig {
        return this.authConfig;
    }
}
