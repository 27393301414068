export enum ErrorCode {
    login = 10,
    loginRedirection = 11,
    logout = 12,
    getUser = 13,
    handleRedirection = 14,
}
export class ErrorAuth extends Error {
    code: ErrorCode;
    constructor(code: ErrorCode) {
        super();
        this.code = code;
    }
    getCode(): ErrorCode {
        return this.code;
    }
}
