import { Injectable, Inject } from '@angular/core';
import { JwtService } from './jwt.service';
import { of, from, Observable, throwError } from 'rxjs';
// Libraries
import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

// Constant
import { AUTH0_CONSTANTS } from '../../core/constant/auth0.constants';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { map } from 'rxjs/internal/operators/map';
import { LoggedUser } from '../../core/model/loggedUser';
import { ErrorCode, ErrorAuth } from '../../core/model/error';
import { ConfigurationService } from './congifuration.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private auth0SPAConfig: Auth0ClientOptions;

    private auth0Client: Auth0Client;

    private logoutPath: string;

    constructor(private configurationService: ConfigurationService, private jwtService: JwtService) {
        const { domain, clientIdWeb, apiId, connection, logoutPath } = configurationService.getAuthConfiguration();
        this.logoutPath = logoutPath;
        this.auth0SPAConfig = {
            client_id: clientIdWeb,
            domain,
            audience: apiId,
            scope: AUTH0_CONSTANTS.SCOPE,
            connection,
            prompt: 'login',
            redirect_uri: `${window.location.origin}/callback`,
        };
    }

    loginSilently(): Observable<LoggedUser> {
        return from(this.getInformationUser()).pipe(
            map((user: LoggedUser) => {
                const { name, email, avatar, token, expiration } = user;
                const loggedUser: LoggedUser = {
                    token,
                    expiration,
                    roles: this.jwtService.getRoles(user.token),
                    name,
                    email,
                    avatar,
                    error: null,
                };
                return loggedUser;
            })
        );
    }
    login(): Observable<any> {
        return from(this.loginWithRedirect());
    }

    // Authentication client

    /**
     * Return the authentication client if it is not created
     */
    async getAuth0Client(): Promise<Auth0Client> {
        if (!this.auth0Client) {
            this.auth0Client = await createAuth0Client(this.auth0SPAConfig);
        }
        return this.auth0Client;
    }

    public async loginWithRedirect() {
        try {
            const client = await this.getAuth0Client();
            return await client.loginWithRedirect({ display: AUTH0_CONSTANTS.SIGNUP_UP } as any);
        } catch (error) {
            throw new ErrorAuth(ErrorCode.loginRedirection);
        }
    }
    /**
     * Logout the user
     */
    public async logout() {
        try {
            const client = await this.getAuth0Client();
            client.logout({
                federated: true,
                client_id: this.auth0SPAConfig.client_id,
                returnTo: `${window.location.origin}/${this.logoutPath}`,
            });
        } catch (error) {
            throw new ErrorAuth(ErrorCode.logout);
        }
    }

    /**
     * Get the user profile if he is logged
     */
    public async getInformationUser() {
        const client: Auth0Client = await this.getAuth0Client();
        let token;
        let expiration;
        try {
            token = await client.getTokenSilently();
            const decodedToken: any = this.jwtService.decodeToken(token);
            expiration = decodedToken.exp * 1000;
        } catch (error) {
            token = this.jwtService.getBaseToken();
            return { name: '', email: '', avatar: '', token: token};
        }
        try {
            const user = await client.getUser();
            const { name, email, picture } = user;
            const userInfo = { name, email, avatar: picture, token, expiration };
            return userInfo;
        } catch (error) {
            throw new ErrorAuth(ErrorCode.login);
        }
    }
}
