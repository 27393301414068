import { Injectable } from '@angular/core';
import { Recently } from '@modules/recently/core/model/recently';
import { RecentlyDatabaseService } from '../db/recently-database.service';

@Injectable({ providedIn: 'root' })
export class RecentlyService {

  constructor(
    private recentlyDB: RecentlyDatabaseService,
  ) {}

  public async getAllRecentlies(): Promise<any> {
    return await this.recentlyDB.getAll();
  }

  public getRecently(serialNumber: string) {
    return this.recentlyDB.get(serialNumber);
  }


  public saveRecently(recently: Recently) {
      return this.recentlyDB.set(recently);
  }

  public removeRecentlyList() {
    return this.recentlyDB.removeAll();
  }

}
