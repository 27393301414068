import { createReducer, Action, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { AuthState, AuthAdapter } from './auth.adapter';

export const initialState: AuthState = AuthAdapter.getInitialState();

const authReducer = createReducer(
    initialState,
    on(AuthActions.setLoggedUser, (state: AuthState, { loggedUser }) => {
        const user = { id: 0, errorCode: null, ...loggedUser };
        return AuthAdapter.upsertOne(user, state);
    }),
    on(AuthActions.setErrorCode, (state: AuthState, { error }) => {
        const user = { id: 0, error, ...state.entities[0] };
        return AuthAdapter.upsertOne(user, state);
    }),
    on(AuthActions.removeErrorCode, (state: AuthState) => {
        const user = { id: 0, error: null, ...state.entities[0] };
        return AuthAdapter.upsertOne(user, state);
    }),
    on(AuthActions.cleanState, (state: AuthState) => {
        return AuthAdapter.removeAll(state);
    })
);

export function reducer(state: AuthState, action: Action) {
    return authReducer(state, action);
}
