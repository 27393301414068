import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ApplicationInfoService } from '../application-info.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ErrorType } from '@core/models/error';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    urlApi: string;

    constructor(private http: HttpClient, private applicationInfoService: ApplicationInfoService) {
        const infoApi: any = this.applicationInfoService.getApiInfo();
        this.urlApi = infoApi.domain + '/' + infoApi.apiService;
    }

    get(uri: string, headers?: HttpHeaders) {
        return this.http.get(`${this.urlApi}/${uri}`, { headers }).pipe(
            map((res) => this.checkForEmptyResult(res)),
            catchError(this.handleError)
        );
    }

    private checkForEmptyResult(result) {
        if (result === null || (result.data && result.data.length === 0)) {
            throw new HttpErrorResponse({ error: { msg: 'No search result found.' }, status: 705 });
        } else {
            return result;
        }
    }

    private handleError(error: HttpErrorResponse) {
        const internet = navigator.onLine;
        const message = error && error.error ? error.error : '';
        if (internet === false) {
            return throwError({ message: null, type: ErrorType.NotInternet });
        } else if (error.status === 403) {
            return throwError({ message: null, type: ErrorType.Forbidden });
        } else if (error.status === 404) {
            return throwError({ message: null, type: ErrorType.NotFound });
        } else if (error.status === 705) {
            return throwError({ message: null, type: ErrorType.NoSearchResult });
        } else if (error.status.toString().includes('40')) {
            return throwError({ message, type: ErrorType.DataError });
        } else {
            return throwError({ message: null, type: ErrorType.InternalError });
        }
    }
}
