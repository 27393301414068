import { createReducer, Action, on } from '@ngrx/store';
import * as DocumentActions from './document.actions';
import { DocumentState, DocumentAdapter } from './document.adapter';
import { ResultType } from '../core/model/result';

export const initialState: DocumentState = DocumentAdapter.getInitialState({ key: '' });

const documentReducer = createReducer(
    initialState,
    on(DocumentActions.setDocumentResult, (state: DocumentState, { result, resultType }) => {
        const document = {
            id: 0,
            result,
            error: null,
            resultType: resultType ? resultType : ResultType.RequestSucess,
        };
        return DocumentAdapter.upsertOne(document, state);
    }),
    on(DocumentActions.setResultTypeDocument, (state: DocumentState, { resultType, statusId }) => {
        const document = { id: 0, result: null, resultType, statusId };
        return DocumentAdapter.upsertOne(document, state);
    }),
    on(DocumentActions.cleanStateDocument, (state: DocumentState) => {
        const document = { id: 0, result: null, resultType: null };
        return DocumentAdapter.upsertOne(document, state);
    }),
    on(DocumentActions.updateDocumentsResult, (state: any, { result, resultType }) => {
        const newResult = {
            data: [...state.entities[0].result.data, ...result.data],
            lastitem: result.lastitem,
        };

        const document = {
            id: 0,
            result: newResult,
            error: null,
            resultType: resultType ? resultType : ResultType.RequestSucess,
        } as any;
        return DocumentAdapter.upsertOne(document, state);
    }),
    on(DocumentActions.updateDocumentsKey, (state: DocumentState, { key }) => {
        return { ...state, key };
    }),
    on(DocumentActions.requestDocumentSucess, (state, { result }) => {
            const document = {
                id: 0,
                result,
                error: null,
                resultType: ResultType.RequestSucess,
            };
            return DocumentAdapter.upsertOne(document, state);

        }
    ),
);

export function reducer(state: DocumentState, action: Action) {
    return documentReducer(state, action);
}
