import { createAction, props } from '@ngrx/store';

export enum SettingActionTypes {
    getLanguage = '[Setting] GET Language',
    setLanguage = '[Settings] SET Language',
    changeLanguage = '[Settings] CHANGE Language',
    initLanguage = '[Settings] INIT Language',
}

export const setLanguage = createAction(SettingActionTypes.setLanguage, props<{ language: any }>());

export const changeLanguage = createAction(SettingActionTypes.changeLanguage, props<{ language: any }>());

export const initLanguage = createAction(SettingActionTypes.initLanguage);
