import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, AuthSelectors, LoggedUser, Role } from 'src/app/state';
import { flatMap, skipWhile } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private store: Store<AppState>) {}

    canActivate(route: ActivatedRouteSnapshot) {
        return this.store.select(AuthSelectors.getLoggedUser).pipe(
            skipWhile((loggedUser: LoggedUser) => !loggedUser),
            flatMap((loggedUser: LoggedUser) => {
                const loggedUserRoles: Role[] = loggedUser ? loggedUser.roles : [];

                if (route.data && route.data.roles && loggedUserRoles) {
                    const canActivate = route.data.roles.some((role) => loggedUserRoles.includes(role));

                    if (!canActivate) {
                        this.router.navigate(['/']);
                        return of(false);
                    }

                    return of(true);
                }

                this.router.navigate(['/']);
                return of(false);
            })
        );
    }
}
