import { createFeatureSelector, createSelector } from '@ngrx/store';
import { recentliesFeatureKey, RecentlyState } from './recently.reducer';

export const getRecentliesState = createFeatureSelector<RecentlyState>(recentliesFeatureKey);
export const deleteRecentlyState = createFeatureSelector<RecentlyState>(recentliesFeatureKey);

export const getRecentlies = createSelector(
  getRecentliesState,
  (state: RecentlyState) => state.recentlies
);

export const deleteRecently = createSelector(
  deleteRecentlyState,
  (state: RecentlyState) => state.recentlies
);