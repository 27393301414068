import { createAction, props } from '@ngrx/store';
import { ResultType } from '../core/model/result';


export enum ProductActionTypes {
    requestMachine = '[API-PRODUCT] REQUEST MACHINE',
    requestMachines = '[API-PRODUCT] REQUEST MACHINES',
    requestVersionHistory = '[API-PRODUCT] REQUEST VERSION HISTORY',
    requestNextMachines = '[API-PRODUCT] REQUEST NEXT MACHINES',
    updateMachineResult = '[API-PRODUCT] UPDATE MACHINE RESULT',
    updateMachineKey = '[API-PRODUCT] UPDATE MACHINE KEY',
    setMachineResult = '[API-PRODUCT] SET MACHINE RESULT',
    setMachineMessageResult = '[API-PRODUCT] SET MACHINE MESSAGE RESULT',
    setVersionHistory = '[API-PRODUCT] SET VERSION HISTORY',
    cleanStateMachine = '[API-PRODUCT] CLEAN STATE MACHINE',
    navigateToMachineDetails = '[API-PRODUCT] SAVE MACHINE VERSION',
    requestMachineHistory = '[API-PRODUCT] REQUEST VERSION HISTORY',
    setMachineHistory = '[API-PRODUCT] SET VERSION HISTORY',
    cleanMachineHistory = '[API-PRODUCT] CLEAN MACHINE HISTORY',
}

export const requestMachine = createAction(
    ProductActionTypes.requestMachine,
    props<{ serialNumber: string; productNumber: string; version: any }>()
);
export const requestMachines = createAction(
    ProductActionTypes.requestMachines,
    props<{ serialNumber: string; productNumber: string }>()
);
export const requestNextMachines = createAction(
    ProductActionTypes.requestNextMachines,
    props<{ serialNumber: string; productNumber: string; lastItem: string }>()
);
export const requestVersionHistory = createAction(
    ProductActionTypes.requestVersionHistory,
    props<{ serialNumber: string; productNumber: string }>()
);
export const setVersionHistory = createAction(
    ProductActionTypes.setVersionHistory,
    props<{ versionhistory: number[] }>()
);
export const updateMachineResult = createAction(
    ProductActionTypes.updateMachineResult,
    props<{ result: any; resultType?: ResultType }>()
);
export const updateMachineKey = createAction(
    ProductActionTypes.updateMachineKey,
    props<{ serialNumber: string; productNumber: string }>()
);
export const setMachineResult = createAction(
    ProductActionTypes.setMachineResult,
    props<{ result: any; resultType?: any }>()
);
export const setResultTypeMachine = createAction(
    ProductActionTypes.setMachineMessageResult,
    props<{ resultType: ResultType }>()
);

export const cleanStateMachine = createAction(ProductActionTypes.cleanStateMachine);

export const navigateToMachineDetails = createAction(
    ProductActionTypes.navigateToMachineDetails,
    props<{ serialNumber: string; productNumber: string; version: any }>()
);
export const requestMachineHistory = createAction(
    ProductActionTypes.requestMachineHistory,
    props<{ serialNumber: string; productNumber: string }>()
);
export const setMachineHistory = createAction(
    ProductActionTypes.setMachineHistory,
    props<{ machinehistory: any[] }>()
);
export const cleanMachineHistory = createAction(ProductActionTypes.cleanStateMachine);
