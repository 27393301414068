// Basic
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

/**
 * Service to publish the configuration of the environment.
 */
@Injectable({ providedIn: 'root' })
export class ConfigurationService {

  /**
   * variable to bind the environment from the window to the service.
   */
  public static environment = environment;


}
