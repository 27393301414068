import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import * as productReducer from './state/product.reducer';
import { StoreModule } from '@ngrx/store';
import { ProductEffects } from './state/product.effects';
import { EffectsModule } from '@ngrx/effects';
import { ApiService } from '@core/services/api/api.service';

@NgModule({
    imports: [
        // Angular
        HttpClientModule,
        CommonModule,
        // Store
        StoreModule.forFeature('product', productReducer.reducer),
        EffectsModule.forFeature([ProductEffects]),
    ],
    providers: [ProductEffects, ApiService],
})
export class ProductModule {}
