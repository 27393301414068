import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentState, DocumentAdapter } from './document.adapter';

const { selectEntities } = DocumentAdapter.getSelectors();

const getDataState = createFeatureSelector<DocumentState>('document');

export const getData = createSelector(getDataState, (state: DocumentState) => {
    const entities = selectEntities(state);
    return entities[0] ? buildResponseResult(entities) : null;
});
export const getResponse = createSelector(getDataState, (state: DocumentState) => {
    const entities = selectEntities(state);
    return entities[0] ? entities[0] : null;
});
export const getKey = createSelector(getDataState, (state: DocumentState) => state.key);

function buildResponseResult(entities) {
    if (entities[0].result) {
        if (entities[0].result.documentid) {
            return [entities[0].result];
        } else {
            return entities[0].result;
        }
    } else {
        return null;
    }
}
