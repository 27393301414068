import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Product } from '../core/model/machine';

export interface ProductState extends EntityState<Product> {
    serialNumber: string;
    productNumber: string;
    versionhistory: number[];
    machinehistory: any[];
}

export const ProductAdapter: EntityAdapter<Product> = createEntityAdapter<Product>();
