import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, AuthActions } from 'src/app/state';

@Injectable({ providedIn: 'root' })
export class LogoutGuard implements CanActivate {
    constructor(private store: Store<AppState>) {}

    canActivate() {
        this.store.dispatch(AuthActions.logout());
        return false;
    }
}
