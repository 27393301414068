import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, AuthActions } from 'src/app/state';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
    constructor(private store: Store<AppState>) {}

    canActivate() {
        this.store.dispatch(AuthActions.login());
        return false;
    }
}
