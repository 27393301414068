export const environment = {
    production: true,
    env: 'Development',
    enableManualLogin: true,
    business: 'Large Drives Applications',
    browserTitle: 'IQ Pinpoint',
    api: {
        domain: 'https://service.sidriveiqtwin-lda-dev.siemens.cloud',
        cookieDomain: 'sidriveiqtwin-lda-dev.siemens.cloud',
        manageAccountEndpoint: 'https://uss.login-qa.siemens.com/',
        apiService: 'api'
    },
    auth: {
        clientIdWeb: '7RG3khrQ4wTe7hkMON9S8Cmj7jSEISWs',
        domain: 'siemens-qa-00133.eu.auth0.com',
        apiId: 'https://custom_api_auth0/v1',
        connection: 'main-tenant-oidc',
        logoutPath: '',
    },
};
