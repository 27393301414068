import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
    storage: any;
    constructor() {
        this.storage = localStorage;
    }
    public setItem(key: string, item: string) {
        return localStorage.setItem(key, item);
    }
    public getItem(key: string): string {
        return localStorage.getItem(key);
    }
}
