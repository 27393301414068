import { Injectable } from '@angular/core';
import { Favorite } from '@modules/favorites/core/model/favorite';
import { FavoriteDatabaseService } from '../db/favorite-database.service';

@Injectable({ providedIn: 'root' })
export class FavoritesService {

  constructor(
    private favoriteDB: FavoriteDatabaseService,
  ) {}

  public async getAllFavorites(): Promise<any> {
    return await this.favoriteDB.getAll();
  }

  public getFavorite(serialNumber: string) {
   // return this.favoriteDB.get(serialNumber).catch(() => { return; });
  }


  public saveFavorite(favorite: Favorite) {
    return this.favoriteDB.set(favorite);
  }

  public removeFavorite(serialNumber: string) {
    return this.favoriteDB.remove(serialNumber);
  }

}
