import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as settingReducer from './state/setting.reducer';
import { StoreModule } from '@ngrx/store';
import { TranslateService } from '@core/services/translate.service';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffects } from './state/setting.effects';
import { StorageService } from '@core/services/storage.service';

@NgModule({
    imports: [
        // Angular
        CommonModule,
        // Store
        StoreModule.forFeature('setting', settingReducer.reducer),
        EffectsModule.forFeature([SettingsEffects]),
    ],
    providers: [TranslateService, SettingsEffects, StorageService],
})
export class SettingModule {}
