import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, mergeMap } from 'rxjs/operators';
import { SettingActionTypes, setLanguage, changeLanguage } from '../actions';
import { TranslateService } from '@core/services/translate.service';
import { StorageService } from '@core/services/storage.service';
import { Language } from '../core/model/setting-language';
import { of } from 'rxjs';

@Injectable()
export class SettingsEffects {
    initLanguage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingActionTypes.initLanguage),
            map((action: any) => {
                const languageDefault = this.storageService.getItem('language');
                const language = languageDefault ? languageDefault : Language.English;
                return changeLanguage({ language });
            })
        );
    });
    changeLanguage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SettingActionTypes.changeLanguage),
            mergeMap((action: any) => {
                this.storageService.setItem('language', action.language);
                return this.translationService.changeLanguage(action.language).pipe(
                    map(() => setLanguage({ language: action.language })),
                    catchError((error) => of(error))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private translationService: TranslateService,
        private storageService: StorageService
    ) {}
}
