import { Injectable } from '@angular/core';
import { AUTH0_CONSTANTS } from '@modules/auth/core/constant/auth0.constants';
import * as jwtGenerator from 'jsonwebtoken';

@Injectable({ providedIn: 'root' })
export class JwtService {
    constructor() {}

    decodeToken(token: string) {
        return jwtGenerator.decode(token);
    }

    getRoles(token: string) {
        const decodedToken = this.decodeToken(token);
        return decodedToken ? decodedToken['https://sidriveiqtwin-roles'] : [];
    }
    getBaseToken(): string {
        return AUTH0_CONSTANTS.BASE_TOKEN;
      }
}
