import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { SettingModule } from '@modules/setting';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomSerializer } from './route/custom-route-serializer';
import { environment } from 'src/environments/environment';
import { ResponseModule } from '@modules/response';;
import { ProductModule } from '@modules/product';
import { AuthModule } from '@modules/auth';
import { ApplicationInfoService } from '@core/services/application-info.service';
import { DocumentModule } from '@modules/documents';
import { FavoritesModule } from '@modules/favorites';
import { RecentlyModule } from '@modules/recently';

@NgModule({
    imports: [
        ProductModule,
        DocumentModule,
        CommonModule,
        FavoritesModule,
        RecentlyModule,
        SettingModule,
        AuthModule.forRoot(ApplicationInfoService.getAuthConfiguration()),
        ResponseModule,
        StoreModule.forRoot({ router: routerReducer }),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer,
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
    ],
    declarations: [],
})
export class RootStoreModule {}
