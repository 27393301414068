import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { FavoritesActionTypes, saveFavorites, saveFavorite, removeFavorite } from './favorites.actions';
import { FavoritesService } from '../infrastructure/service/favorites.service';
import { Favorite } from '../core/model/favorite';
import { from } from 'rxjs';
//import { updateRecentlyIsBookmarked } from '@modules/recently/actions';

@Injectable()
export class FavoritesEffects {
    getFavoritesFromDB$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavoritesActionTypes.requestFavorites),
            mergeMap(() => this.getFavoritesAction()),
            mergeMap((saveFavoriteAction) => saveFavoriteAction)
        );
    });
    private async getFavoritesAction() {
        let newFavorites = await this.favoriteService.getAllFavorites();

        return [ 
            saveFavorites({ favorites: newFavorites }),
        ];
    }

    saveFavoriteIntoDB$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavoritesActionTypes.saveFavoriteIntoDB),
            mergeMap((action: any) => this.saveFavoriteAction(action.favorite)),
            mergeMap((saveFavoriteAction) => saveFavoriteAction)
        );
    });



    private async saveFavoriteAction(favorite: Favorite) {
        await this.favoriteService.saveFavorite(favorite);

        return [ 
            saveFavorite({ favorite: favorite }),
            //updateRecentlyIsBookmarked({ serialNumber: favorite.serialNumber, isBookmarked: true })
        ];
    }

    deleteFavoriteFromDB$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavoritesActionTypes.deleteFavoriteFromDB),
            mergeMap((action: any) => this.removeFavoriteAction(action.favorite)),
            mergeMap((removeFavoriteAction) => removeFavoriteAction)
            );
    });
    
    private async removeFavoriteAction(favorite: Favorite) {
        await this.favoriteService.removeFavorite(favorite.serialNumber);

        return [ 
            removeFavorite({ favorite: favorite }),
            //updateRecentlyIsBookmarked({ serialNumber: favorite.serialNumber, isBookmarked: true })
        ];
    }

    constructor(
        private actions$: Actions,
        private favoriteService: FavoritesService
    ) { }
}
