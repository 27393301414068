import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService as Translate } from '@ngx-translate/core';
import { SETTINGS_CONSTANTS } from '@core/constants/settings.constants';

@Injectable({ providedIn: 'root' })
export class TranslateService {
    constructor(private translate: Translate) {
        // this.translate.use( this.translate.getDefaultLang() );
    }

    /**
     * Method that returns the translations for the param
     * @param lang language key to get the translation
     */
    public getLanguageTranslation(lang: string): Observable<any> {
        return this.translate.getTranslation(lang);
    }
    /**
     * translate a single key
     * @param key key to be translated
     * @param params additional params to be inserted
     */
    public instant(key: string, params?) {
        return this.translate.instant(key, params);
    }
    /**
     * Method to get the current language
     */
    public currentLanguage(): string {
        return this.translate.currentLang;
    }

    public getTranslation(key: string): Observable<any> {
        return this.translate.get(key);
    }

    public getInstantTranslation(key: string): string {
        return this.translate.instant(key);
    }
    public changeLanguage(language: string): Observable<any> {
        return this.translate.use(language);
    }
    /**
     * provides the default/fallback language
     */
    public defaultLanguage() {
        return SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[0];
    }
}
