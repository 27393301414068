import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import {
    DocumentActionTypes,
    requestDocumentSucess,
    setDocumentResult,
    updateDocumentsKey,
} from './document.actions';
import { forkJoin, of } from 'rxjs';
import { DocumentApiService } from '../infrastructure/service/document.service';
import { Sha256Service } from '../core/service/sha256.service';
import { ApiError } from '../core/model/api-error';

@Injectable()
export class DocumentEffects {

    getDocuments$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DocumentActionTypes.requestDocuments),
            mergeMap((action: any) =>
                this.documentApiService.getDocuments(action.id).pipe(
                    mergeMap((result: any) => [setDocumentResult({ result }), updateDocumentsKey({ key: action.id })]),
                    catchError((error: ApiError) =>
                        of(setDocumentResult({ result: error.message, resultType: error.type }))
                    )
                )
            )
        );
    });

    getDocumentByMachineVersion$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(DocumentActionTypes.requestDocument),
          mergeMap((action: any) => {
    
            const documentIdsFromMachineVersion = this.documentApiService.extractDocumentIdsFromMachineVersion(
              action.version
            );
    
            if (documentIdsFromMachineVersion.length > 0) {
    
              const requestDocumentIds = documentIdsFromMachineVersion.map((documentId) =>
                this.documentApiService.getDocumentInfoById(documentId)
              );
    
              return forkJoin(requestDocumentIds).pipe(
                map((result) => {
                  const filterResult = result.filter((obj) => Object.keys(obj).length > 0);  // Filter for successful requests
                  return requestDocumentSucess({ result: filterResult });
                })
              );
    
            } else {
              return of(requestDocumentSucess({ result: [] }));
            }
    
          })
        );
      });

    constructor(
        private actions$: Actions,
        private documentApiService: DocumentApiService,
    ) {}
}
