export enum Language {
    English = 'en',
    German = 'de',
    Chinese = 'zh',
}
interface LanguageState {
    currentLanguage: Language;
}
export interface Setting {
    id: number;
    language: LanguageState;
}
