import { createAction, props } from '@ngrx/store';

export enum ResponseActionTypes {
    setLoading = '[API-RESPONSE] SET LOADING',
    dismissLoading = '[API-RESPONSE] DISMISS LOADING'
}

export const setLoading = createAction(ResponseActionTypes.setLoading, props<{ loading: boolean }>());

export const dismissLoading = createAction(ResponseActionTypes.dismissLoading, 
    props<Record<string, unknown>>()
);