export enum ResultType {
    Forbidden = 403,
    NotFound = 404,
    DataError = 400,
    InternalError = 500,
    NotInternet = 600,
    RequestSucess = 700,
    SaveSucess = 701,
    DeleteSucess = 702,
    NoSearchResult = 705,
}
