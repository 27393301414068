import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { settingActions, AppState, AuthActions } from './state';
import { ApplicationInfoService } from '@core/services/application-info.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dtwin-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    private popupCloseSubscription: Subscription;
    statusChangeSubscription: Subscription;
    cookieConsentOpen: boolean;

    constructor(private store: Store<AppState>, 
        private applicationInfoService: ApplicationInfoService, 
        private dbService: NgxIndexedDBService, 
        private ccService: NgcCookieConsentService,
        private titleService: Title,
        ) {}

    ngOnInit(): void {
        this.store.dispatch(settingActions.initLanguage());

        this.store.dispatch(AuthActions.loginSilently());
        const title = this.applicationInfoService.getBrowserTitle();
        this.titleService.setTitle(title);
        
        const isConsented = this.getCookie('cookieconsent_status');
        if(isConsented.length == 0){
            //cookie is not available, display blocking overlay and wait for cookie acceptance
            this.cookieConsentOpen = true;

            this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
            () => {
                this.cookieConsentOpen = false;
                this.popupCloseSubscription.unsubscribe();
            });
        }    
    }

    public getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let c: string;
    
        for (let i: number = 0; i < caLen; i += 1) {
          c = ca[i].replace(/^\s+/g, '');
          if (c.indexOf(name) == 0) {
            return c;
          }
        }
        return '';
      }
    
}
