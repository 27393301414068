import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as responseReducer from './state/response.reducer';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        CommonModule,
        // Store
        StoreModule.forFeature('response', responseReducer.reducer),
    ],
})
export class ResponseModule {}
