import { createReducer, Action, on } from '@ngrx/store';
import * as MachineActions from './product.actions';
import { ProductState, ProductAdapter } from './product.adapter';
import { ResultType } from '../core/model/result';

export const initialState: ProductState = ProductAdapter.getInitialState({
    serialNumber: '',
    productNumber: '',
    versionhistory: [],
    machinehistory: []
});

const productReducer = createReducer(
    initialState,
    on(MachineActions.setMachineResult, (state: ProductState, { result, resultType }) => {
        const product = {
            id: 0,
            result: !result || Array.isArray(result) ? result : [result],
            error: null,
            resultType: resultType ? resultType : ResultType.RequestSucess,
        };
        return ProductAdapter.upsertOne(product, state);
    }),
    on(MachineActions.setResultTypeMachine, (state: ProductState, { resultType }) => {
        const product = { id: 0, result: null, resultType };
        return ProductAdapter.upsertOne(product, state);
    }),
    on(MachineActions.cleanStateMachine, (state: ProductState) => {
        const product = { id: 0, result: null, resultType: null };
        return ProductAdapter.upsertOne(product, state);
    }),
    on(MachineActions.updateMachineKey, (state: ProductState, { serialNumber, productNumber }) => {
        return { ...state, serialNumber, productNumber };
    }),
    on(MachineActions.setVersionHistory, (state: ProductState, { versionhistory }) => {
        return { ...state, versionhistory };
    }),
    on(MachineActions.updateMachineResult, (state: any, { result, resultType }) => {
        let newResult;
        if (state.entities[0].result[0]) {
            newResult = {
                data: [...state.entities[0].result[0].data, ...result.data],
                lastitem: result.lastitem,
            };
        } else if (state.entities[0].result.data) {
            newResult = {
                data: [...state.entities[0].result.data, ...result.data],
                lastitem: result.lastitem,
            };
        }
        const product = {
            id: 0,
            result: newResult,
            error: null,
            resultType: resultType ? resultType : ResultType.RequestSucess,
        } as any;
        return ProductAdapter.upsertOne(product, state);
    }),
    on(MachineActions.setMachineHistory, (state: ProductState, { machinehistory }) => {
        return { ...state, machinehistory };
    }),
    on(MachineActions.cleanMachineHistory, (state: ProductState) => {
        const machinehistory = [];
        return { ...state, machinehistory };
    }),
);

export function reducer(state: ProductState, action: Action) {
    return productReducer(state, action);
}
