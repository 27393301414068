import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AuthActionTypes, setLoggedUser, loginRedirectionSucess, logoutSucess, setErrorCode } from './auth.actions';
import { LoggedUser } from '../core/model/loggedUser';
import { AuthService } from '../infrastructure/service/auth.service';
import { from } from 'rxjs/internal/observable/from';
import { ErrorAuth } from '../core/model/error';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class AuthEffects {
    loginSilently$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.loginSilently),
            mergeMap(() => {
                return this.authService.loginSilently().pipe(
                    map((loggedUser: LoggedUser) => setLoggedUser({ loggedUser })),
                    catchError((error: ErrorAuth) => of(setErrorCode({ error })))
                );
            })
        );
    });
    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.logout),
            mergeMap(() => {
                return from(this.authService.logout()).pipe(
                    map(() => logoutSucess()),
                    catchError((error: ErrorAuth) => of(setErrorCode({ error })))
                );
            })
        );
    });
    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.login),
            mergeMap(() => {
                return this.authService.login().pipe(
                    map(() => loginRedirectionSucess()),
                    catchError((error: ErrorAuth) => of(setErrorCode({ error })))
                );
            })
        );
    });

    constructor(private actions$: Actions, private authService: AuthService) {}
}
