// Basic
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as packageJSON from '../../../../package.json';
import { AuthConfig } from 'src/app/state';
import { PrefilledDataSearchModel } from '@core/models/prefilled-data-search-machine.model';
import { PREFILLED_DATA_CONSTANTS } from '@core/constants/prefilled-data.constant';

@Injectable({ providedIn: 'root' })
export class ApplicationInfoService {
    /**
     * variable to bind the environment from the window to the service.
     */
    environment = environment;

    /**
     * variable to bind the package.json from the window to the service
     */
    packageApp = packageJSON['default'];

    copyright = {
        COMPANY: 'Siemens',
        MINIMUM_LIMIT: 2019,
        SIMBOL: '\u00A9', // '©'
    };
    static getAuthConfiguration(): AuthConfig {
        return environment.auth;
    }

    public getCopyright(): string {
        const currenYear = new Date().getFullYear();
        const licenceInterval =
            this.copyright.MINIMUM_LIMIT === currenYear
                ? currenYear
                : `${this.copyright.MINIMUM_LIMIT} - ${currenYear}`;
        return `${this.copyright.SIMBOL} ${this.copyright.COMPANY} ${licenceInterval}`;
    }

    public getVersion(): string {
        return this.packageApp.version;
    }

    public getBusiness(): string {
        return this.environment.business;
    }

    public getEnvironment(): string {
        return this.environment.env;
    }
    public getApiInfo(): object {
        return this.environment.api;
    }
    public isManualLoginEnabled(): boolean {
        return this.environment.enableManualLogin;
    }
    public getBrowserTitle(): string {
        return this.environment.browserTitle;
    }
    public getPrefilledDataMachineSearch(): PrefilledDataSearchModel {
        return environment.env !== 'Production' ?
            { serialNumber: PREFILLED_DATA_CONSTANTS.SEARCH_MACHINE.SERIAL_NUMBER, productNumber: PREFILLED_DATA_CONSTANTS.SEARCH_MACHINE.PRODUCT_NUMBER }
            :
            { serialNumber: '', productNumber: '' };
    }
}
