
import { Injectable } from '@angular/core';
import { Favorite } from '../../core/model/favorite';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class FavoriteDatabaseService {

  constructor(private dbService: NgxIndexedDBService) {
  }

  public async get(key: string) {
    await this.dbService.getByKey('_favorites', key).subscribe((favorite) => {
      return favorite;
    });  
  }

  public async set(value: Favorite) {
    await this.dbService
    .update('_favorites', {
      serialnumber: value.serialNumber,
      productnumber: value.productNumber,
    }).toPromise();
  }

  public async getAll(): Promise<any> {
    var response = await this.getData().toPromise();
    return response;
  }

  getData(): Observable<any>{
    return this.dbService.getAll('_favorites');
  }

  public async remove(serialnumber: string){
    var key;
    this.dbService.getByID('_favorites', serialnumber).subscribe((result)=>{
      if (result){
        key = result;
      }
    });
    await this.dbService.deleteByKey('_favorites', serialnumber).toPromise();  
  }

  public removeAll() {
    this.dbService.clear('_favorites');
  }
}
