import { createAction, props } from '@ngrx/store';
import { Recently } from '../core/model/recently';

export enum RecentlyActionTypes {
    requestRecentlies = '[DB-RECENTLIES] REQUEST RECENTLIES',
    saveRecentlies = '[RECENTLIES] SAVE RECENTLIES',
    saveRecentlyIntoDB = '[DB-RECENTLIES] SAVE RECENTLY',
    saveRecently = '[RECENTLIES] SAVE RECENTY',
    deleteRecentlyFromDB = '[DB-RECENTLIES] DELETE RECENTLY',
    removeRecentlyList = '[RECENTLIES] REMOVE RECENTLY LIST',
}

export const requestRecentlies = createAction( RecentlyActionTypes.requestRecentlies, 
    props<Record<string, unknown>>()
);

export const saveRecentlies = createAction( RecentlyActionTypes.saveRecentlies,
    props<{ recentlies: Recently[] }>()
);

export const saveRecentlyIntoDB = createAction( RecentlyActionTypes.saveRecentlyIntoDB,
    props<{ recently: Recently }>()
);

export const saveRecently = createAction( RecentlyActionTypes.saveRecently,
    props<{ recently: Recently }>()
);

export const deleteRecentlyFromDB = createAction( RecentlyActionTypes.deleteRecentlyFromDB,
    props<{ recently: Recently }>()
 );

 export const removeRecentlyList = createAction( RecentlyActionTypes.removeRecentlyList );
