import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingState, SettingAdapter } from './setting.adapter';

const { selectEntities } = SettingAdapter.getSelectors();

const getSettingState = createFeatureSelector<SettingState>('setting');

export const getSettings = createSelector(getSettingState, (state: SettingState) => {
    const entities = selectEntities(state);
    return entities[0];
});
