import { createAction, props } from '@ngrx/store';
import { Favorite } from '../core/model/favorite';

export enum FavoritesActionTypes {
    requestFavorites = '[DB-FAVORITES] REQUEST FAVORITES',
    saveFavorites = '[FAVORITES] SAVE FAVORITES',
    saveFavoriteIntoDB = '[DB-FAVORITES] SAVE FAVORITE',
    saveFavorite = '[FAVORITES] SAVE FAVORITE',
    deleteFavoriteFromDB = '[DB-FAVORITES] DELETE FAVORITE',
    removeFavorite = '[FAVORITES] REMOVE FAVORITE',
}

export const requestFavorites = createAction( FavoritesActionTypes.requestFavorites, 
    props<Record<string, unknown>>()
);

export const saveFavorites = createAction( FavoritesActionTypes.saveFavorites,
    props<{ favorites: Favorite[] }>()
);

export const saveFavoriteIntoDB = createAction( FavoritesActionTypes.saveFavoriteIntoDB,
    props<{ favorite: Favorite }>()
);

export const saveFavorite = createAction( FavoritesActionTypes.saveFavorite,
    props<{ favorite: Favorite }>()
);

export const deleteFavoriteFromDB = createAction( FavoritesActionTypes.deleteFavoriteFromDB,
    props<{ favorite: Favorite }>()
 );

 export const removeFavorite = createAction( FavoritesActionTypes.removeFavorite,
    props<{ favorite: Favorite }>()
 );
