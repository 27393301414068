import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import * as documentReducer from './state/document.reducer';
import { StoreModule } from '@ngrx/store';
import { DocumentEffects } from './state/document.effects';
import { EffectsModule } from '@ngrx/effects';
import { ApiService } from '@core/services/api/api.service';

@NgModule({
    imports: [
        // Angular
        HttpClientModule,
        CommonModule,
        // Store
        StoreModule.forFeature('document', documentReducer.reducer),
        EffectsModule.forFeature([DocumentEffects]),
    ],
    providers: [ApiService, DocumentEffects],
})
export class DocumentModule {}
