import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { RecentlyActionTypes, saveRecentlies, saveRecently } from './recently.actions';
import { RecentlyService } from '../infrastructure/service/recently.service';
import { Recently } from '../core/model/recently';
//import { updateRecentlyIsBookmarked } from '@modules/recently/actions';

@Injectable()
export class RecentlyEffects {
    getRecentliesFromDB$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RecentlyActionTypes.requestRecentlies),
            mergeMap(() => this.getRecentlyAction()),
            mergeMap((saveRecentlyAction) => saveRecentlyAction)
        );
    });
    private async getRecentlyAction() {
        let newRecentlies = await this.recentlyService.getAllRecentlies();

        return [ 
            saveRecentlies({ recentlies: newRecentlies }),
        ];
    }

    saveRecentlyIntoDB$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RecentlyActionTypes.saveRecentlyIntoDB),
            mergeMap((action: any) => this.saveRecentlyAction(action.recently)),
            mergeMap((saveRecentlyAction) => saveRecentlyAction)
        );
    });



    private async saveRecentlyAction(recently: Recently) {
        await this.recentlyService.saveRecently(recently);

        return [ 
            saveRecently({ recently: recently }),
        ];
    }

    deleteRecentlysFromDB$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RecentlyActionTypes.removeRecentlyList),
            mergeMap((action: any) => this.deleteRecentlysAction()),
            mergeMap((saveRecentlyAction) => saveRecentlyAction)
        );
    });
    private async deleteRecentlysAction() {
        await this.recentlyService.removeRecentlyList();

        return [ 
            saveRecentlies({ recentlies: [] }),
        ];
    }

    constructor(
        private actions$: Actions,
        private recentlyService: RecentlyService
    ) { }
}
